import { render, staticRenderFns } from "./payIndirect.vue?vue&type=template&id=64bf285e&scoped=true&"
import script from "./payIndirect.vue?vue&type=script&lang=js&"
export * from "./payIndirect.vue?vue&type=script&lang=js&"
import style0 from "./payIndirect.vue?vue&type=style&index=0&id=64bf285e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bf285e",
  null
  
)

export default component.exports