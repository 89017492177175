<template>
  <div class="center">
    <img
      src="@/assets/images/prev_white.png"
      alt=""
      class="back_icon"
      @click="toBack"
    />
    <!-- <img src="@/assets/images/salt_bg.jpg" alt="" /> -->
    <div class="img">
      <img src="@/assets/images/salt_1.png" alt="" @click="handleToapp1">
      <img src="@/assets/images/salt_2.png" alt="" @click="handleToapp2">
      <img src="@/assets/images/salt_3.png" alt="" @click="handleToapp3">
    </div>
    <div class="test"></div>
    <div class="btn">
      <div @click="handleToapp">欢迎洽谈合作</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    toBack () {
      window.uni.navigateBack({
        delta: 1
      })
    },
    handleToapp () {
      window.uni.navigateTo({
        url: '/pages/price/store?org_id=O166787162271537&power=public'
      })
    },
    handleToapp1 () {
      window.uni.navigateTo({
        url: '/pages/product/product/publicProductDetail?product_id=PI166795885504733211542&org_id=O166787162271537&unit_id=PU166795885505997726862'
      })
    },
    handleToapp2 () {
      window.uni.navigateTo({
        url: '/pages/product/product/publicProductDetail?product_id=PI166795903248024744363&org_id=O166787162271537&unit_id=PU166795903249264229726'
      })
    },
    handleToapp3 () {
      window.uni.navigateTo({
        url: '/pages/product/product/publicProductDetail?product_id=PI166787561077900471434&org_id=O166787162271537&unit_id=PU166787561079104206129'
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.center {
  width: 100%;
  // height: 100vh;
  background: url('../../assets/images/salt_bg.jpg') no-repeat;
  background-size: 100% 100%;
  img {
    width: 100%;
    box-sizing: border-box;
  }
  .btn {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0px);
    padding: 0px 20px;
    width: 100%;
    height: 41px;
    box-sizing: border-box;
    div {
      background-color: #41bac5;
      color: #fff;
      font-size: 16px;
      letter-spacing: 4px;
      text-align: center;
      line-height: 41px;
      border-radius: 8px;
    }
  }
  .back_icon {
    position: fixed;
    top: 3.7333vw;
    left: 3.7333vw;
    height: 5.3333vw;
    width: 5.3333vw;
  }
  .img{
    padding: 130% 10px 0px 15px;
    width: 100%;
    box-sizing: border-box;
  }
  .test{
    height: 75px;
    width: 100%;
    background-color: #f0a331;
  }
}
</style>
