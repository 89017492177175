<template>
    <div class="center">
      <div class="title">
        <van-icon size="20" @click="toBack" name="arrow-left" />
        <span>付款</span>
        <div style="width: 20px;"></div>
      </div>
      <div class="name">
        向xxx零售付款：
        <span>￥</span>
        <span>{{amount}}</span>
      </div>
      <div class="btn" @click="handleCmomit">
        付款
      </div>
      <div class="tip">
        支付服务由支付服务由广农数链信息科技有限公司提供
      </div>
    </div>
  </template>

<script>
export default {
  data () {
    return {
      amount: '44'
    }
  },
  methods: {
    toBack () {
      window.uni.navigateBack({
        delta: 1
      })
    },
    handleCmomit () {
      if (this.amount === '') {
        this.$toast.fail('请先输入金额')
      } else {
        // 调起微信支付宝支付
        console.log('调起微信支付宝支付')
        this.$router.push({
          path: '/collection',
          query: {
            amount: this.amount,
            receipt_org_name: '',
            pay_org_name: '',
            pay_methods: 'WX',
            back_url: '',
            pay_channel: 'wx',
            url: 'http%3A%2F%2F192.168.10.20%3A10100%2Ffund_account%2Fsuccess_html%3Faccount%3Dplatform%26amount%3D100.00%26code%3DAO16690215709480053362%26url_type%3Dplatform%26is_frozen%3D0%26payment_type%3D13%26pay_org_id%3DO164826057727284%26receipt_org_id%3DO162711668801379%26pay_platform%3Duniapp%26order_code%3DAO16690215709480053362%26mine_org_id%3DO164826057727284%26mine_user_code%3DU164826057727282',
            account: 'platform',
            code: 'AO16690215709480053362',
            url_type: 'platform',
            is_frozen: '0',
            payment_type: '13',
            pay_org_id: 'O164826057727284',
            receipt_org_id: 'O162711668801379',
            pay_platform: 'uniapp',
            order_code: 'AO16690215709480053362',
            mine_org_id: 'O164826057727284',
            mine_user_code: 'U164826057727282'
          }
        })
      }
    }
  }
}
</script>
  <style lang="scss" scoped>
  .center{
    .title{
      padding: 0px 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      background: #ffffff;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFangSC-Medium-Medium;
      font-weight: 500;
      text-align: CENTER;
      color: #202122;
      box-sizing: border-box;
    }
    .back_icon {
      position: fixed;
      top: 3.7333vw;
      left: 3.7333vw;
      height: 5.3333vw;
      width: 5.3333vw;
    }
    .name{
        margin: 0 auto;
        margin-top: 103px;
        text-align: center;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: rgba(0,0,0,0.9);
        span:nth-child(1){
            font-size: 13px;
            font-family: D-DIN, D-DIN-DIN-Bold;
            font-weight: bold;
            text-align: CENTER;
            color: #202122;
        }
        span:nth-child(2){
            font-size: 16px;
            font-family: DIN-Bold;
            font-weight: bold;
            text-align: CENTER;
            color: #202122;
        }
    }
    .btn{
        margin: 0 auto;
        margin-top: 24px;
        width: 300px;
        height: 36px;
        background: #00a0ac;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 36px;
    }
    .tip{
        margin: 0 auto;
        margin-top: 46px;
        font-size: 11px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #999999;
    }
  }
  </style>
