<template>
  <div class="center">
    <div class="title">
      <van-icon size="20" @click="toBack" name="arrow-left" />
      <span>付款</span>
      <div style="width: 20px;"></div>
    </div>
    <div class="content">
      <div class="icon">
        <img src="../../assets/images/6718.png" alt="">
      </div>
      <div class="text">支付成功</div>
      <div class="item">
        <div class="left">
          订单号：
        </div>
        <div class="right">
          10003633666666
        </div>
      </div>
      <div class="item">
        <div class="left">
          付款金额：
        </div>
        <div class="right" style="color: #FA675C">
          50.00元
        </div>
      </div>
      <div class="item">
        <div class="left">
          付款方：
        </div>
        <div class="right">
          xxx零售店
        </div>
      </div>
      <div class="item">
        <div class="left">
          付款方式：
        </div>
        <div class="right">
          微信支付
        </div>
      </div>
      <div class="item">
        <div class="left">
          流水号：
        </div>
        <div class="right">
          576464853443333
        </div>
      </div>
      <div class="item">
        <div class="left">
          支付信息：
        </div>
        <div class="right">
          消费
        </div>
      </div>
      <div class="item">
        <div class="left">
          下单时间：
        </div>
        <div class="right">
          2019-09-04 15:22:09
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    toBack () {
      window.uni.navigateBack({
        delta: 1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.center{
  .title{
    padding: 0px 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #ffffff;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFangSC-Medium-Medium;
    font-weight: 500;
    text-align: CENTER;
    color: #202122;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
  }
  .back_icon {
    position: fixed;
    top: 3.7333vw;
    left: 3.7333vw;
    height: 5.3333vw;
    width: 5.3333vw;
  }
  .content{
    padding: 47px 22px 0px;
    width: 100%;
    height: calc(100vh - 50px);
    box-sizing: border-box;
    .icon{
      margin: 0 auto;
      width: 70px;
      height: 70px;
      img{
        width: 70px;
        height: 70px;
      }
    }
    .text{
      margin: 0 auto;
      margin-top: 26px;
      margin-bottom: 43px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: CENTER;
      color: #202122;
    }
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      .left{
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: #999999;
      }
      .right{
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: #202122;
      }
    }
  }
}
</style>
