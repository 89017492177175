<template>
  <div class="center">
    <div class="top">
      <img
        src="@/assets/images/prev_blank.png"
        alt=""
        class="back_icon"
        @click="toBack"
      />
      <div class="title">联系客服</div>
      <div style="opacity: 0">1111</div>
    </div>
    <div class="bgimg">
      <!-- <img class="img" src="../../assets/images/bgImg.png" alt="" /> -->
    </div>
    <b href="#" class="btn" @click="callPhone">点击拨打</b>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    toBack () {
      window.uni.navigateBack({
        delta: 1
      })
    },
    callPhone () {
      window.location.href = 'tel://' + '020-82258374'
    }
  }
}
</script>
  <style lang="scss" scoped>
.center {
  .top {
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 11vw;
    width: 100%;
    background-color: #f8f8f8;
    box-sizing: border-box;
    .back_icon {
      height: 5.3333vw;
      width: 5.3333vw;
    }
  }

  .bgimg {
    width: 100%;
    height: calc(100vh - 11vw);
    background-image: url("../../assets/images/bgImg.png");
    background-size: 100% 100%;
  }
  .phone {
    padding: 10px 30px;
    position: fixed;
    right: 7vw;
    bottom: 17vw;
    background: coral;
    color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .btn {
    padding: 10px 30px;
    box-sizing: border-box;
    position: fixed;
    right: 5vw;
    bottom: 25vw;
    color: #fff;
    font-size: 5vw;
    text-transform: uppercase;
    cursor: pointer;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 60px;
  }

  .btn {
    animation: animate 8s linear infinite;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }

    100% {
      background-position: 400%;
    }
  }
}
</style>
