<template>
    <div class="center">
      <img
        src="@/assets/images/prev_white.png"
        alt=""
        class="back_icon"
        @click="toBack"
      />
      <img class="img" src="../../assets/images/union_2.jpg" alt="">
      <img class="img" src="../../assets/images/union_1.jpg" alt="">
    </div>
  </template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    toBack () {
      console.log(this.statusBarHeight)
      window.uni.navigateBack({
        delta: 1
      })
    }
  }
}
</script>
  <style lang="scss" scoped>
  .center{
    .back_icon {
      position: fixed;
      top: 3.7333vw;
      left: 3.7333vw;
      height: 5.3333vw;
      width: 5.3333vw;
    }
    .img{
      width: 100vw;
      img{
        width: 100vw;
      }
    }
  }
  </style>
