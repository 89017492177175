<template>
    <div class="page">
      <template>
        <van-nav-bar left-arrow
                     @click-left="onClickLeft">
          <img src="@/assets/images/prev_blank.png"
               slot="left"
               alt=""
               class="back-icon" />
          <span slot="title"
                class="title">收银台</span>
        </van-nav-bar>

        <div class="content">
          <p class="content-title">应付金额</p>
          <p><span style="font-size: 16px">¥</span> {{ amount }}</p>
        </div>

        <div class="card">
          <p class="card-title">支付方式</p>
          <div>
            <van-radio-group v-model="type">
              <div class="type-item" v-if="pay_setting==='WX' || pay_setting==='ALL'">
                <div class="type-left"
                     @click="type = '1'">
                  <img src="@/assets/images/wechat.png"
                       alt=""
                       class="type-icon" />
                  微信支付
                </div>
                <van-radio name="1"
                           :icon-size="18"
                           checked-color="#00A0AC"></van-radio>
              </div>
              <div class="type-item" v-if="pay_setting==='ZFB' || pay_setting==='ALL'">
                <div class="type-left"
                     @click="type = '2'">
                  <img src="@/assets/images/zhifubao.png"
                       alt=""
                       class="type-icon" />
                  支付宝支付
                </div>
                <van-radio name="2"
                           :icon-size="18"
                           checked-color="#00A0AC"></van-radio>
              </div>
            </van-radio-group>
          </div>
        </div>

        <div class="footer">
          <div class="footer-button"
               @click="pay">确认支付</div>
        </div>
      </template>
    </div>
  </template>

<script>
import { payUnified } from '@/api/public'
import { pay, paySetting } from '@/api/form.js'
// import VConsole from 'vconsole'

// eslint-disable-next-line no-new
// new VConsole()

export default {
  data () {
    return {
      type: '1',
      amount: '',
      init: false,
      pay_setting: ''
    }
  },
  created () {
    console.log(this.$route.query)
    document.title = '收银台'
    this.amount = this.$route.query.amount
    this.isAlipayOrWechat()
  },
  methods: {
    isAlipayOrWechat () {
      var userAgent = window.navigator.userAgent.toLowerCase()
      if (userAgent.match(/Alipay/i) === 'alipay') {
        // alert("支付宝浏览器");
        this.pay_setting = 'ZFB'
      } else if (userAgent.match(/MicroMessenger/i) === 'micromessenger') {
        // alert("微信浏览器");
        this.pay_setting = 'WX'
      } else {
        this.pay_setting = 'ALL'
      }
      console.log(this.pay_setting, '谢谢谢谢谢谢')
    },
    onClickLeft () {
      this.$router.back()
    },
    // 获取静态链接跳
    getBackUrl (code) {
      var host = window.location.host
      let url = 'http://192.168.0.193:8080/#'
      const params = ''
      if (host === 't-static-h5.tevv.com.cn') {
        url = 'http://t-static-h5.tevv.com.cn/#' // 测试域名
      } else if (host === 'p-static-h5.tevv.com.cn') {
        url = 'https://p-static-h5.tevv.com.cn/#' // 正式域名
      } else if (host === 'r-static-h5.tevv.com.cn') {
        url = 'http://r-static-h5.tevv.com.cn/#' // 正式域名
      }
      return url + params
    },
    // 获取app链接
    getPaySueesccUrl () {
      var host = window.location.host
      let url = 'http://t-nz-app.tevv.com.cn'
      if (host === 't-static-h5.tevv.com.cn') {
        url = 'http://t-nz-app.tevv.com.cn' // 测试域名
      } else if (host === 'p-static-h5.tevv.com.cn') {
        url = 'https://p-nz-app.tevv.com.cn' // 正式域名
      } else if (host === 'r-static-h5.tevv.com.cn') {
        url = 'http://r-nz-app.tevv.com.cn' // 正式域名
      }
      return url
    },
    pay () {
      console.log(this.type, 'xxxxxx')
      const url = encodeURIComponent(this.getBackUrl(this.$route.query.code))
      const back_url = `${this.getPaySueesccUrl()}/fund_account/success_html?order_code=${this.$route.query.code}&url=${url}&payPlatform=h5`
      const pay_methods = this.type === '1' ? 'WX' : 'ZFB'
      payUnified(
        // {
        //   code: this.$route.query.code,
        //   url_type: 'platform',
        //   is_frozen: '0',
        //   account: 'platform',
        //   pay_org_id: this.$route.query.org_id,
        //   receipt_org_id: this.$route.query.receipt_org_id,
        //   amount: this.$route.query.amount,
        //   url: this.type === '1' ? encodeURIComponent(back_url) : back_url,
        //   back_url: '',
        //   payment_type: this.$route.query.payment_type,
        //   pay_methods: this.type === '1' ? 'WX' : 'ZFB',
        //   pay_channel: this.type === '1' ? 'wx' : 'zfb',
        //   //   pay_channel: this.pay_setting[pay_methods].pay_channel_code,
        //   remark: '',
        //   agree_id: '',
        //   pay_platform: 'H5'
        // }
        {
          amount: this.amount,
          receipt_org_name: '',
          pay_org_name: '',
          pay_methods: this.type === '1' ? 'WX' : 'ZFB',
          back_url: '',
          pay_channel: this.type === '1' ? 'wx' : 'zfb',
          url: this.type === '1' ? encodeURIComponent(back_url) : back_url,
          account: 'platform',
          code: 'AO16690215709480053362',
          url_type: 'platform',
          is_frozen: '0',
          payment_type: '13',
          pay_org_id: 'O164826057727284',
          receipt_org_id: 'O162711668801379',
          pay_platform: 'uniapp',
          order_code: 'AO16690215709480053362',
          mine_org_id: 'O164826057727284',
          mine_user_code: 'U164826057727282',
          agree_id: '',
          remark: '1111'
        }
      )
        .then((res) => {
          localStorage.removeItem('isDisabledGet')
          localStorage.removeItem('payPhone')
          // return
          if (this.type === '1') { // 微信
            const url = `${this.getPaySueesccUrl()}/fund_account/h5pay?url=${encodeURIComponent(res.url)}`
            location.replace(url)
          } else {
            location.replace(res.url)
          }
        })
        .catch((err) => {
          this.$toast(err.msg || '支付失败')
        })
    }
  }
}
</script>

  <style lang="scss" scoped>
  .page {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background: #f3f4f6;

    .back-icon {
      height: 20px;
    }

    .title {
      font-weight: 400;
      font-size: 14px;
    }

    .content {
      padding: 30px;
      text-align: center;
      color: #fa675c;
      font-size: 22px;

      .content-title {
        padding-bottom: 14px;
        font-size: 14px;
        color: #202122;
      }
    }

    .card {
      background: #fff;
      border-radius: 4px;
      margin: 0 8px;
      padding: 15px 0 5px;
      padding-left: 15px;
      .card-title {
        padding-bottom: 10px;
        font-size: 15px;
      }

      .type-item {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0;
        padding-right: 20px;
        padding-left: 5px;

        &:first-child {
          border-bottom: 1px solid #eee;
        }

        .type-left {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }

      .type-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      background: #fff;
      padding: 5px 8px;
      color: #fff;
      left: 0;
      right: 0;

      .footer-button {
        background: #00a0ac;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-size: 15px;
        border-radius: 4px;
      }
    }
  }
  </style>
