<template>
  <div class="wrapper">
    <img style="width:100%;height: auto;"
         src="@/assets/images/coupon-bg.png"
         mode="" />
    <div class="bc-p-row-10">
      <div style="width:100%;height:176px;position: relative;">
        <img style="width:100%;height:100%;position: absolute;top: 0px;left: 0px;z-index: 0;"
             src="@/assets/images/flyhj.png"
             mode="" />
        <div style="position: relative;z-index: 10;">
          <div class="bc-row-center bc-align-center"
               style="width:100%;height:54px;color: #e2403e;font-size: 16px;font-weight: 500"
               @click="showDialog2 = true">{{detail.coupon_name}}
            <img src="@/assets/images/toast.png"
                 style="width:12px;height:12px;margin-left: 5px;margin-top: -5px;"
                 alt="">
          </div>
          <div style="width:100%;height:122px"
               class="bc-col-center bc-align-center">
            <div>
              <span style="font-size: 18px;color: #e2403e;font-weight: normal;">￥</span>
              <span style="font-size: 28px;color: #e2403e;font-weight: normal;">{{detail.face_value}}</span>
            </div>
            <div v-if="detail.type == '1'"
                 style="color:#E2403E;font-size: 14px;margin-top: 5px;margin-bottom: 5px;">满{{detail.threshold}}元可用</div>
            <div v-if="detail.type == '0'"
                 style="color:#E2403E;font-size: 14px;margin-top: 5px;margin-bottom: 5px;">无门槛</div>
            <div style="color: rgba(0,0,0,0.70);font-size: 12px;">有效期：{{detail.end_time}}</div>
          </div>
        </div>
        <div class="btn"
             @click="receive">点击领取</div>
      </div>
    </div>
    <van-popup v-model="showPopup"
               position="bottom"
               closeable
               round
               :close-on-click-overlay="true">
      <div class="popup">
        <div class="popup-title">登录</div>
        <div class="logo">
          <img src="@/assets/images/bc.png"
               alt="">
        </div>
        <div class="popup-form">
          <!-- <div class="popup-form-title">手机号</div> -->
          <input maxlength="11"
                 type="text"
                 class="input"
                 v-model="phone"
                 placeholder="请输入手机号" />
        </div>
        <div class="popup-form">
          <!-- <div class="popup-form-title">验证码</div> -->
          <div class="input-content">
            <input maxlength="6"
                   type="text"
                   class="input"
                   v-model="sms_code"
                   placeholder="请输入验证码" />
            <span :style="{ color: !interval ? '#00a0ac' : '#A3A3A3' }"
                  @click="sendMessage('3')">{{ codeBtnStr }}</span>
          </div>
        </div>
        <div style="color: #808388;font-size: 12px;margin-top:10px">未注册的手机号登录后将自动创建宝秤账号</div>
        <div class="popup-button"
             @click="login">登录</div>
      </div>
    </van-popup>
    <van-dialog v-model="showGetDialog"
                :show-confirm-button="false">
      <div class="dialog">
        <img src="@/assets/images/6718.png"
             alt=""
             class="dialog-icon" />
        <p class="dialog-title">领取成功</p>
        <p style="text-align:left;line-height:20px">您已成功领取{{conut}}张优惠券，请前往宝秤农资APP使用！</p>
        <div class="dialog-footer">
          <div class="dialog-button dialog-cancel-button"
               @click="showGetDialog = false">
            知道了
          </div>
          <div class="dialog-button"
               @click="toDownload">下载APP</div>
        </div>
      </div>
    </van-dialog>

    <van-dialog v-model="showDialog"
                :show-confirm-button="false">
      <div class="dialog">
        <p class="dialog-title">提示</p>
        <p style="text-align:left;line-height:20px">{{toast}}</p>
        <div class="dialog-footer">
          <div class="dialog-button dialog-cancel-button"
               @click="showDialog = false">
            知道了
          </div>
          <div class="dialog-button"
               @click="toDownload">下载APP</div>
        </div>
      </div>
    </van-dialog>

    <van-dialog v-model="showDialog2"
                :show-confirm-button="false">
      <div class="dialog">
        <p class="dialog-title">使用规则</p>
        <div class="rule-item">
          <span class="sp1">使用范围：</span>
          <span class="sp2">宝秤农资APP指定商品可用</span>
        </div>
        <div class="rule-item">
          <span class="sp1">使用店铺：</span>
          <span class="sp2">{{detail.org_name}}</span>
        </div>
        <div class="rule-item">
          <span class="sp1">使用时间：</span>
          <span class="sp2">{{detail.start_time}}-{{detail.end_time}}</span>
        </div>
        <div class="rule-item"
             v-if="detail.desc">
          <span class="sp1">备注：</span>
          <span class="sp2">{{detail.desc}}</span>
        </div>
        <!-- <p style="text-align:left;line-height:20px">使用范围：宝秤农资APP指定商品可用</p>
        <p style="text-align:left;line-height:20px">使用店铺：{{detail.org_name}}</p>
        <p style="text-align:left;line-height:20px">使用时间：{{detail.start_time}}-{{detail.end_time}}</p>
        <p style="text-align:left;line-height:20px"
           v-if='detail.desc'>备注 {{detail.desc}}</p> -->
        <div class="dialog-footer">
          <!-- <div class="dialog-button dialog-cancel-button"
               @click="showDialog = false">
            知道了
          </div> -->
          <div class="dialog-button"
               @click="showDialog2 = false">知道了</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { getOrgCouponDetail, receiveOrgCoupon } from '@/api/public.js'
import { smsLogin } from '@/api/user.js'
import { getSmsCode } from '@/api/hongbao'
export default {
  components: {},
  props: {},
  data () {
    return {
      showDialog: false,
      showPopup: false,
      showDialog2: false,
      showGetDialog: false,
      codeBtnStr: '获取验证码',
      interval: 0,
      phone: '',
      conut: 0,
      sms_code: '',
      isLogin: false,
      loginData: {},
      detail: {

      },
      toast: '您不是当前商家的好友，该优惠券仅限该商家好友可领取，请前往APP添加好友后再领取!',
      code: ''
    };
  },
  watch: {},
  computed: {},
  methods: {
    receive () {
      if (!this.isLogin) {
        this.showPopup = true
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      receiveOrgCoupon({
        "seller_org_id": this.detail.org_id, // 商家org_id
        "org_id": this.loginData.org_id, //我的org_id
        "user_code": this.loginData.user_code, //我的user_code
        "receive_type": "0", //0:领取一张 1:领取商家的全部优惠券
        "code": this.code  //指定优惠券code 当receive_type为0时, 这个参数必须有
      }).then(res => {
        loading.close()
        console.log(res)
        if (res.total == -1) {
          this.showDialog = true
        } else {
          this.conut = res.total
          this.showGetDialog = true
        }
      }).catch(err => {
        this.$toast(err.msg)
        console.log(err)
        loading.close()
      })
    },
    getOrgCouponDetail () {
      getOrgCouponDetail({
        code: this.code
      }).then(res => {
        console.log(res)

        this.detail = res
      })
    },
    login () {
      const loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      smsLogin({
        mobile: this.phone,
        sms_code: this.sms_code,
        empower: ''
      }).then(res => {
        loading.close()
        console.log(res)
        this.loginData = res
        this.showPopup = false
        this.isLogin = true
        // window.localStorage.setItem('user_info', JSON.stringify(res))
        this.phone = ''
        this.sms_code = ''
        console.log(res)
      }).catch(err => {
        loading.close()
        console.log(err)
        this.$toast(err.msg)
      })
    },
    sendMessage (template_id) {
      if (this.interval > 0) {
        return
      }
      getSmsCode({
        phone_number: this.phone,
        template_id: template_id

      })
        .then((res) => {
          this.interval = 60
          this.timer()
        })
        .catch((err) => {
          this.$toast(err.msg)
        })
    },
    timer () {
      this.interval--
      if (this.interval > 0) {
        this.codeBtnStr = '重新获取(' + this.interval + 's)'
        setTimeout(() => {
          this.timer()
        }, 1000)
      } else {
        this.codeBtnStr = '获取验证码'
      }
    },
    toDownload () {
      this.$router.push({
        name: 'Download'
      })
    },
  },
  created () {
    document.title = '领取商家优惠券'
    this.code = this.$route.query.code
    this.getOrgCouponDetail()
  },
  mounted () { }
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  background: #ec4a3f;
}
.btn {
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: linear-gradient(#f8b727, #f8690a);
  border: 2px solid #ffca97;
  border-radius: 25px;
  color: #ffffff;
  font-size: 20px;
  margin: 20px 0px;
}
.popup {
  padding: 0 30px;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
    .img {
      width: 80px;
      height: 80px;
      background: red;
    }
  }
  .popup-title {
    padding: 25px 0 32px;
    font-size: 16px;
  }

  .popup-form {
    border-bottom: 1px #ddd solid;
    margin-bottom: 10px;

    .input {
      border: none;
      outline: none;
      padding: 8px 0;
      color: #202122;
    }

    .popup-form-title {
      color: #505255;
      font-size: 13px;
    }
  }

  .input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00a0ac;
  }

  .popup-button {
    background: #00a0ac;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 70px 0 30px;
    border-radius: 8px;
  }
}
.dialog {
  text-align: center;
  padding: 20px;
  color: #505255;
  font-size: 14px;
  line-height: 1;

  .dialog-icon {
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
  }

  .dialog-title {
    font-size: 18px;
    padding-bottom: 18px;
    color: #202122;
  }

  .dialog-footer {
    display: flex;
    padding-top: 18px;

    .dialog-button {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #fff;
      background: #00a0ac;
      border-radius: 4px;
      flex: 1;
    }

    .dialog-cancel-button {
      color: #505255;
      background: #f3f4f6;
      margin-right: 10px;
    }
  }
}
.rule-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  .sp1 {
    width: 80px;
    text-align: right;
    line-height: 20px;
  }
  .sp2 {
    flex: 1;
    text-align: left;
    line-height: 20px;
  }
}
</style>