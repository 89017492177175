<template>
    <div class="center">
      <van-icon class="back_icon" color="#a1a7a7" size="20" @click="toBack" name="arrow-left" />
      <img class="img" src="@/assets/images/tip.png" alt="">
      <div class="text">功能正在开发中</div>
    </div>
  </template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    toBack () {
      window.uni.navigateBack({
        delta: 1
      })
    }
  }
}
</script>
  <style lang="scss" scoped>
  .center{
    padding-top: 164px;
    width: 100%;
    height: 100%;
    color: #666;
    font-size: 22px;
    text-align: center;
    box-sizing: border-box;

    .back_icon {
      position: fixed;
      top: 3.7333vw;
      left: 3.7333vw;
      height: 5.3333vw;
      width: 5.3333vw;
    }
    .img{
      width: 297px;
      height: 160px;
    }
    .text{
      margin-top: 37px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFangSC-Medium-Medium;
      font-weight: 500;
      text-align: CENTER;
      color: #a1a7a7;
    }
  }
  </style>
