import { api } from '@/plugins/axios'
import { mangeApi } from '@/plugins/mangeAxios'
import { app } from '@/plugins/appAxios'

export function applyMarket (params) {
  return api('/profession_market/add_apply_market_org', params)
}

export function searchMarket (params) {
  return api('/profession_market/apply_market_list', params)
}
export function getStatisticsList (params) {
  return api('/profession_market/apply_market_org_statistics_list', params)
}

// 获取商品分类
export function getClassifyList (params) {
  return mangeApi('industry/classify_list', params)
}

// 根据关键字获取商品分类
export function getDesignatedClassifyList (params) {
  return mangeApi('search/classify', params)
}

// 根据关键字获取商品分类
export function getArticleProductDetail (params) {
  return mangeApi('/extension_article/product_detail', params)
}

// 创建推广订单
export function createGenerateOrder (params) {
  return mangeApi('/generate/generate_order/create', params)
}

// 统一支付
export function pay (params) {
  return app('/pay/unified', params)
}

// 统一支付
export function paySetting (params) {
  return app('/pay/pay_setting', params)
}

export function setProductVisit (params) {
  return mangeApi('/extension_article/add_product_summary', params)
}

export function addProductVisit (params) {
  return mangeApi('/extension_article/product_visit/add', params)
}
