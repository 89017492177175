<template>
    <div class="center">
      <div class="title">
        <van-icon size="20" @click="toBack" name="arrow-left" />
        <span>付款</span>
        <div style="width: 20px;"></div>
      </div>
      <div class="name">
        xxx零售商行：
      </div>
      <div class="input">
        <div class="content">
          <div class="icon">￥</div>
          <div class="input_item" style="flex: 1">
            <div style="width: 1px; height: 24px; background: #000;" :class="isactive ? 'active' : ''"></div>
            <van-field @click="show = true" readonly size="large" style="flex: 1" v-model="amount" placeholder="请输入付款金额" />
          </div>
        </div>
      </div>
      <div class="btn" @click="handleCmomit">
        付款
      </div>
      <div class="tip">
        支付服务由广农数链信息科技有限公司提供
      </div>
      <van-number-keyboard
        :show="show"
        theme="custom"
        extra-key="."
        close-button-text="确定"
        @blur="show = false"
        @input="onInput"
        @delete="onDelete"
      />
    </div>
  </template>

<script>
export default {
  data () {
    return {
      show: true,
      amount: '',
      isactive: true,
      timer: null
    }
  },
  watch: {
    // amount: {
    //   handler (newVal, oldVal) {
    //     if (newVal === '' && this.show) {
    //       this.timer = setInterval(() => {
    //         this.isactive = !this.isactive
    //       }, 500)
    //     } else {
    //       this.isactive = true
    //       clearInterval(this.timer)
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // },
  },
  methods: {
    onDelete () {
      this.amount = this.amount.substr(0, this.amount.length - 1)
    },
    onInput (value) {
      this.amount = this.amount + value
      this.limitInput('amount')
    },
    limitInput (objKey) { // 金额正则限制
      this[objKey] = this[objKey].replace(/[^0-9.]/g, '')
      // 解决首位直接输入 '0开头的数字'问题
      if (this[objKey].length == 2 && this[objKey].charAt(0) == 0 && this[objKey].charAt(1) != '.') {
        this[objKey] = this[objKey].charAt(1)
      }
      // 解决开始就输入点的问题
      if (this[objKey].length == 2 && this[objKey].charAt(0) == '.') {
        this[objKey] = '0' + String(this[objKey])
      }
      // 解决两个点的问题
      if (this[objKey].indexOf('.') != this[objKey].lastIndexOf('.')) {
        this[objKey] = this[objKey].substr(0, this[objKey].length - 1)
      }
      // 解决两位小数的问题
      const pointIndex = this[objKey].indexOf('.')
      if (pointIndex > 0 && (this[objKey].length - pointIndex) > 3) {
        this[objKey] = this[objKey].substr(0, pointIndex + 3)
      }
    },
    handleCmomit () {
      if (this.amount === '') {
        this.$toast.fail('请先输入金额')
        this.show = true
      } else {
        // 调起微信支付宝支付
        console.log('调起微信支付宝支付')
        this.$router.push({
          path: '/collection',
          query: {
            amount: this.amount,
            receipt_org_name: '',
            pay_org_name: '',
            pay_methods: 'WX',
            back_url: '',
            pay_channel: 'wx',
            url: 'http%3A%2F%2F192.168.10.20%3A10100%2Ffund_account%2Fsuccess_html%3Faccount%3Dplatform%26amount%3D100.00%26code%3DAO16690215709480053362%26url_type%3Dplatform%26is_frozen%3D0%26payment_type%3D13%26pay_org_id%3DO164826057727284%26receipt_org_id%3DO162711668801379%26pay_platform%3Duniapp%26order_code%3DAO16690215709480053362%26mine_org_id%3DO164826057727284%26mine_user_code%3DU164826057727282',
            account: 'platform',
            code: 'AO16690215709480053362',
            url_type: 'platform',
            is_frozen: '0',
            payment_type: '13',
            pay_org_id: 'O164826057727284',
            receipt_org_id: 'O162711668801379',
            pay_platform: 'uniapp',
            order_code: 'AO16690215709480053362',
            mine_org_id: 'O164826057727284',
            mine_user_code: 'U164826057727282'
          }
        })
      }
    },
    handleBlur () {
      console.log('失焦')
    },
    toBack () {
      window.uni.navigateBack({
        delta: 1
      })
    }
  }
}
</script>
  <style lang="scss" scoped>
  .center{
    .title{
      padding: 0px 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      background: #ffffff;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFangSC-Medium-Medium;
      font-weight: 500;
      text-align: CENTER;
      color: #202122;
      box-sizing: border-box;
    }
    .back_icon {
      position: fixed;
      top: 3.7333vw;
      left: 3.7333vw;
      height: 5.3333vw;
      width: 5.3333vw;
    }
    .name{
        margin-top: 63px;
        padding-left: 37px;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: rgba(0,0,0,0.90);
        box-sizing: border-box;
    }
    .input{
        margin-top: 10px;
        padding: 0px 19px;
        display: flex;
        width: 100vw;
        box-sizing: border-box;
        .content{
            padding: 12px 5px 12px 5px;
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #dddddd;
            box-sizing: border-box;
            .icon{
                font-size: 24px;
                font-family: DIN-Bold;
                font-weight: normal;
                color: #202122;
            }
            .input_item{
              display: flex;
              margin-left: 10px;
            }
            ::v-deep .van-field__control{
                font-size: 20px !important;
            }
            ::v-deep .van-cell{
                 padding: 0px !important;
                 box-sizing: border-box;
            }
        }

    }
    .btn{
        margin: 0 auto;
        margin-top: 46px;
        width: 300px;
        height: 36px;
        background: #00a0ac;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 36px;
    }
    .tip{
        margin: 0 auto;
        margin-top: 58px;
        font-size: 11px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #999999;
    }
    ::v-deep .van-key{
        height: 55px !important;
    }
    ::v-deep .van-number-keyboard__sidebar{
        display: block;
    }
    ::v-deep .van-key--blue{
        margin-top: 55px;
        height: 176px !important;
        background-color: #00A0AC !important;
    }
    .active{
      visibility: hidden;
    }
  }
  </style>
